import { tns } from 'tiny-slider/src/tiny-slider';

window.onload = function () {
  let preLoader = document.getElementById('pre-loader');
  preLoader.classList.add('stop');
}


/* tiny slider */

var slider = tns({
    container: '.main-slider',
    mode: 'gallery',
    animateDelay: 1200,
    items: 1,
    controls: false,
    nav: false,
    autoplay: false,
    autoplayButtonOutput: false,
    controlsContainer: document.querySelector('.slider-controls'),
    lazyload: true,
    autoplay: true,
    autoplayTimeout: 5500,
    speed: 1000
  });

  //smooth scroll
  function scrollTo(element) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: element.offsetTop
    });
  }
  
  document.getElementById("page-0").addEventListener('click', () => {
    scrollTo(document.getElementById("page-1"));
  });